/*----------  libraries
------------------------------------------------------------------------------*/
@import "partials/reset";
@import "lib/font-awesome";
@import "config/variables";
@import "lib/slider";
* {
    box-sizing: border-box;
    &::before, &::after {
        box-sizing: border-box;
    }
}
body, html {
    font-family: 'Open Sans', sans-serif;
}
/*----start-wrap-----*/
.wrap {
    width: 80%;
    margin: 0 auto;
}
/*---start-header-----*/
.header {
    display: block;
    width: 100%;
    background: $color-grey-1 url('../images/interceptor-banner.jpg') no-repeat center;
    background-size: cover;
    height: 466px;
    img {
        display: none;
    }
}

/*----start-content-----*/
.top-grids {
    position: relative;
    display: block;
    padding: 4em 0;
    background: $color-orange-1;
    text-align: center;
    &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 8px;
        background-image: linear-gradient(to right, $color-grey-1 50%, rgba(255,255,255,0) 0%);
        background-size: 40px 8px;
        background-repeat: repeat-x;
    }
    &::before {
        top: 0;
        background-position: top;
    }
    &::after {
        bottom: 0;
        background-position: bottom;
    }

    h3 {
        color: $color-white;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1em;
    }

    h2 {
        color: $color-white;
        font-size: 2em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0.2em;
    }
    
    a {
        display: inline-block;
        background-color: $color-red-1;
        color: $color-white;
        padding: 0.5em 1.5em;
        border: 2px solid $color-grey-1;
        line-height: 1;
        border-radius: 50px;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2em;
        margin: 1em;
        &:hover {
            background-color: $color-orange-1;
        }
    }
}
/*---start-mid-grids-----*/
.mid-grids {
    position: relative;
    padding: 5.5em 0 6em;
    background: no-repeat 50% 101% $color-grey-1;
    text-align: center;
    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        bottom: 0;
        left: 50%;
        margin-left: -51px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 51px 39px 51px;
        border-color: transparent transparent $color-orange-1 transparent;
    }
    
    h3 {
        text-align: center;
        color: $color-white;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1em;
        
        a {
            color: $color-orange-1;
        }
    }
}

.mid-grid {
    float: left;
    width: 31.33%;
    margin-right: 2%;
    text-align: center;

    h3 {
        color: $color-white;
        font-size: 3em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    h4 {
        color: $color-orange-1;
        font-size: 1.5em;
        padding: 0.5em 0;
    }

    .icons {
        font-size: 8vw;
        color: $color-white;
    }
}

.button {
    color: $color-white;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 0.5em 0 0;
    transition: all .2s;
    outline: none;

    &:hover {
        color: $color-orange-1;
    }
    
    i {
        margin-left: 10px;
    }
}

.services {
    
    background: url("../images/bottom-bg.png") no-repeat 50% 101% $color-white;
    padding-bottom: 3em;

    h4 {
        font-size: 1.5em;
        color: #363638;
        text-transform: uppercase;
        padding: 1em 0 0;
    }
}
/*----bottom-grids-----*/
.bottom-grids {
    padding: 5em 0 4em;
    background: $color-orange-1;
    position: relative;
}

.social-links {
    text-align: center;

    ul li {
        display: inline-block;

        a {
            font-size: 3em;
            color: $color-white;
            text-transform: uppercase;
            font-weight: bold;
            display: inline-block;
            
        }
        &:not(:last-child) a{
            margin: 0 2em 0 0;
        }
    }
}

.mobile-no {
    text-align: center;

    span {
        font-size: 7vw;
        text-transform: uppercase;
        color: $color-white;
        font-weight: bold;
        padding: 0.2em 0 0.1em;
        display: block;
    }
}

.footer-logo {
    text-align: center;

    a {
        font-size: 3em;
        color: $color-white;
        text-transform: uppercase;
    }
}

.copy-right {
    text-align: center;

    p {
        font-size: 1em;
        text-transform: uppercase;
        color: $color-white;
    }

    a {
        color: $color-grey-1;
        margin: 1em 0;
        display: inline-block;

        &:hover {
            color: $color-white;
        }
    }
}
/*** move top **/
.scroll {
    width: 48px;
    height: 48px;
    border: none;
    display: block;
    text-indent: 100%;
    background: url("../images/top-icon.png") no-repeat right top;
    position: absolute;
    right: 14px;
    bottom: 5px;

    &:hover {
        opacity: 0.8;
    }
}
/*---about-us----*/
.about-us {
    padding: 30px 0 0;
    background: url("../images/bottom-bg.png") no-repeat 50% 101% $color-white;
}

.about-header h3 {
    
    font-size: 1.5em;
    color: #363638;
    text-transform: uppercase;
}

.about-info {
    a {
        text-transform: uppercase;
        font-size: 1.2em;
        
        color: #555;
        transition: 0.5s ease;
        padding: 1em 0 0.5em;
        display: block;

        &:hover {
            color: $color-orange-1;
        }
    }

    p {
        font-size: 0.875em;
        color: rgba(85, 81, 81, 0.84);
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.8em;
    }
}
/*-----specials-heading----*/
.specials-heading h5 {
    float: left;
    width: 33%;
    height: 1px;
    background: #D1D1D1;
    margin: 24px 0 0;
}
/*----special-grid------*/
.special-grid {
    float: left;
    width: 32%;
    margin-right: 2%;
    text-align: center;
}

.specials {
    margin-top: 1em;
}

.special-grid {
    img {
        width: 100%;
    }

    a {
        text-transform: uppercase;
        font-size: 1.2em;
        
        color: #555;
        transition: 0.5s ease;
        padding: 0.3em 0;
        display: block;

        &:hover {
            color: $color-orange-1;
        }
    }

    p {
        font-size: 0.875em;
        color: rgba(148, 145, 145, 0.84);
        font-family: Arial, Helvetica, sans-serif;
        padding: 0 1em 1em;
        line-height: 1.5em;
    }
}

.spe-grid {
    margin: 0;
}

.specials-grids {
    padding: 25px 0 60px;
}
/*-----specials-heading----*/
.specials-heading h3 {
    
    font-size: 1.5em;
    color: #363638;
    text-transform: uppercase;
}
/*----start-testmonial-grid----*/
.testmonial-grid {
    text-align: center;

    h3 {
        
        font-size: 2em;
        color: #363638;
        text-align: center;
        text-transform: uppercase;
        padding: 0 0 0.5em;
    }

    p {
        font-size: 0.875em;
        color: rgba(85, 81, 81, 0.84);
        font-family: Arial, Helvetica, sans-serif;
        padding: 0 1em 1em;
        line-height: 1.8em;
    }

    a {
        font-size: 0.875em;
        font-size: 1.2em;
        
        color: $color-orange-1;
    }
}

.testmonials {
    padding: 3em 0 5em;
    background: url("../images/bottom-bg.png") no-repeat 50% 101% $color-grey-1;
}
/*---start-what-we----*/
/*---start-gallery---*/
.gallerys {
    
    background: url("../images/bottom-bg.png") no-repeat 50% 101% $color-white;
    padding-bottom: 2em;

    h3 {
        
        font-size: 1.5em;
        color: #363638;
        text-transform: uppercase;
        padding: 1em 0;
    }
}

.gallery-grid {
    width: 31.44%;
    float: left;

    p {
        font-size: 0.875em;
        padding: 0.2em 0 1.2em;
        color: #6a6a6a;
        line-height: 1.8em;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.gallery-button a {
    color: $color-grey-1;
    font-size: 0.875em;
    text-transform: uppercase;
    padding: 0.8em;
    border: 1px solid #EEE;
    display: inline-block;

    &:hover {
        background: $color-orange-1;
        color: $color-white;
    }
}

.grid2 {
    margin: 0 35px;
}

.gallery-grids {
    margin-bottom: 30px;
}

.projects-bottom-paination {
    ul li {
        display: inline-block;
    }
    text-align: center;
    padding: 55px 0;
    

    li {
        a {
            font: 400 12px/1.2em Arial;
            color: #5a5a5a;
            padding: 11px 18px;
            background: $color-white;
            text-decoration: none;
            text-shadow: none;
            border: 1px solid #e9e9e9;
            margin-top: 16px;
        }

         > a:hover,
        &.active > a {
            background: $color-orange-1;
            color: $color-white;
        }
    }
}

.gallery-grid h4 {
    
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #363638;
    transition: all 0.3s ease-out;
    cursor: pointer;
    padding: 13px 0 2px;

    &:hover {
        color: $color-orange-1;
    }
}
/*---start-services----*/
/*  GRID OF Five   ============================================================================= */
.section {
    clear: both;
    padding: 0;
    margin: 0;
}

.group {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
    zoom: 1;
}

.col {
    display: block;
    float: left;
    margin: 1% 0 1% 1.6%;

    &:first-child {
        margin-left: 0;
    }
}

.images_1_of_5 {
    width: 15.72%;
    padding: 1.5%;
    text-align: center;

    img {
        max-width: 100%;
        display: inline-block;
    }

    h2 {
        
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #363638;
        transition: all 0.3s ease-out;
        cursor: pointer;
        padding: 13px 0 2px;
    }

    p {
        font-size: 0.875em;
        padding: 0.5em 0;
        color: #6a6a6a;
        line-height: 1.8em;
        font-family: Arial, Helvetica, sans-serif;
    }

    .button a {
        color: $color-grey-1;
        font-size: 0.8em;
        text-transform: uppercase;
        padding: 0.8em;
        border: 1px solid #EEE;
        display: inline-block;
        font-weight: normal;

        &:hover {
            background: $color-orange-1;
            color: $color-white;
        }
    }
}
/***** Media Quries *****/
@media only screen and (max-width: 1024px) {
    .wrap {
        width: 90%;
    }
}
/*----End-wrap-----*/

/***** Media Quries *****/
@media only screen and (max-width: 1024px) {
    .wrap {
        width: 95%;
    }
}
/*  GO FULL WIDTH AT LESS THAN 800 PIXELS */
@media only screen and (max-width: 800px) {
    .wrap {
        width: 95%;
    }

    .span_2_of_3 {
        width: 94%;
        padding: 3%;
    }

    .col {
        margin: 1% 0;
    }

    .span_1_of_3 {
        width: 94%;
        padding: 3%;
    }
}
/*  GO FULL WIDTH AT LESS THAN 640 PIXELS */
@media only screen and (max-width: 640px) and (min-width: 480px) {
    .wrap {
        width: 95%;
    }

    .span_2_of_3 {
        width: 94%;
        padding: 3%;
    }

    .col {
        margin: 1% 0;
    }

    .span_1_of_3 {
        width: 94%;
        padding: 3%;
    }

    .contact-form {
        input[type="text"],
        textarea {
            width: 97%;
        }
    }
}
/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */
@media only screen and (max-width: 480px) {
    .wrap {
        width: 95%;
    }

    .span_2_of_3 {
        width: 90%;
        padding: 5%;
    }

    .col {
        margin: 1% 0;
    }

    .span_1_of_3 {
        width: 90%;
        padding: 5%;
    }

    .contact-form {
        input[type="text"],
        textarea {
            width: 92%;
        }
    }
}
/*----responsive-design-----*/
@media only screen and (max-width: 1366px) and (min-width: 1280px) {
    .wrap {
        width: 90%;
    }

    .grid2 {
        margin: 0 34px;
    }
}
@media only screen and (max-width: 1280px) {
    .header {
        height: 300px;
    }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) {
    .wrap {
        width: 90%;
    }

    .grid2 {
        margin: 0 34px;
    }

    .top-grids {
        h2 {
            color: $color-white;
            font-size: 2em;
        }
        background: $color-orange-1;
    }

    .gallery-grid {
        width: 31.3%;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .wrap {
        width: 90%;
    }

    .grid2 {
        margin: 0 34px;
    }

    .gallery-grid {
        width: 30%;
    }

    .what {
        padding: 25px 12px;
    }
    .mid-grid h3 {
        font-size: 2em;
    }
}
@media only screen and (max-width: 768px) and (min-width: 640px) {
    .wrap {
        width: 90%;
    }

    .grid2 {
        margin: 0 34px;
    }

    .gallery-grid {
        width: 29%;
    }

    .what {
        padding: 25px 12px;
    }

    .top-nav ul li a {
        padding: 45px 10px 40px;
    }

    .mobile-no span {
        font-size: 4em;
    }

    .images_1_of_5 {
        width: 47%;

        &:last-child {
            display: none;
        }
    }
}
@media only screen and (max-width: 768px) {
    .header {
        height: 250px;
    }
    .mid-grid {
        width: 100%;
        margin-bottom: 30px;
        
        h3 {
            font-size: 2em;
        }
        
        h4 {
            font-size: 1.2em;
        }
    }
    
    .footer-logo a {
        font-size: 1.5em;
    }
}
@media only screen and (max-width: 600px) {
    .header {
        height: 150px;
    }
    
}
